<template>
  <div id="intelligentConstructionDetail">
    <div class="it-detail">
      <!-- <breadcrumb class="cm-container"></breadcrumb> -->
      <div class="breadCrumbs">
        <span style="color: #999999;cursor: pointer;" @click="indexClick">首页</span>>
        <span style="color: #999999;cursor: pointer;" @click="courseClick">系统实训</span>>
        <span>{{intelitem.systemExperienceName}}</span>
      </div>

      <div class="BasicInformation">
        <div style="margin: 0.8%;">
          <img style="height:98%;width:340px;object-fit: cover;" :src="`${baseUrl}/${intelitem.pictureUrl}`" alt="" srcset="">
        </div>
        <div class="BasicRight">
          <div class="BasicRightitem">
            <div class="BasicTitle">平台信息</div>
            <div class="Basicflx">
              <div class="Basicflxitem">
                <span class="flxitemtitle">语言：</span>
                <span class="flxitemtext">{{intelitem.systemLanguage}}</span>
              </div>
              <div class="Basicflxitem">
                <span class="flxitemtitle">系统发布时间：</span>
                <span class="flxitemtext">{{ intelitem.publishTime }}</span>
              </div>
            </div>
          </div>
          <div class="BasicRightitem">
            <div class="BasicTitle">适配终端</div>
            <div class="Basicflx">
              <div class="flxitemtext">
                {{intelitem.Adaptiveterminal}}
              </div>
            </div>
          </div>
          <div class="BasicRightitem">
            <div class="BasicTitle">权属信息</div>
            <div class="Basicflx">
              <div class="Basicflxitem">
                <span class="flxitemtitle">版权所有人：</span>
                <span class="flxitemtext">{{intelitem.copyrightOwner}}</span>
              </div>
              <div class="Basicflxitem">
                <span class="flxitemtitle">来源组织：</span>
                <span class="flxitemtext">{{intelitem.affiliatedOrganization}}</span>
              </div>
            </div>
          </div>
         <div class="BasicRightitem">
            <div class="BasicTitle">应用属性</div>
            <div class="Basicflx">
              <div class="Basicflxitem">
                <span class="flxitemtitle">适用专业：</span>
                <span class="flxitemtext">{{intelitem.majorName}}</span>
              </div>
              <div class="Basicflxitem">
                <span class="flxitemtitle">适用行业：</span>
                <span class="flxitemtext">{{intelitem.industryName}}</span>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="boxCenter">
        <div>
          <span class="centitle">{{intelitem.systemExperienceName}}</span>
          <!-- <el-button @click="pingfendialoging" style="margin-left: 10px;" type="warning" round size="mini">评分</el-button>
          <div class="cenping" >
              <span style="font-size: 14px;">评分：</span>
              <div style="">
                <el-rate
                v-model="zhiduRate"
                disabled
                text-color="#ff9900"
                />
              </div>
          </div> -->
        </div>
        <div style="padding: 0 15px 0 0;">
         <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-left: 10px;padding:0 40px;" @click="goexamine">考核</el-button>
         <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-left: 10px;padding:0 40px;" @click="scoreClick">去实训</el-button>
        </div>

     </div>

    <el-dialog :model-value="pingfendialog" title="评分" :before-close="beforedialog" width="20%" align-center style="position:relative;">
      <div>

        <div class="cenping">
          <div style="font-size: 14px;">系统满意度：</div>
          <el-rate
              v-model="caozuoRate"
              text-color="#ff9900"
              size="large"
              />
        </div>

        <div style="margin:5% 0 0 25%;padding-top:1%;">
          <el-button round style="margin-left: 10px;padding:0 40px;" @click="beforedialog">取消</el-button>
          <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-left: 10px;padding:0 40px;" @click="quedingdialog">确定</el-button>
        </div>  
      </div>
      
    </el-dialog>


      <div class="boxFour" >
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane label="平台介绍" name="first">
            <div>
                <p style="font-size: 14px;font-weight: 400;color: #333333;">{{intelitem.introduce}}</p>
            </div>
          </el-tab-pane>
          <el-tab-pane label="实训目标" name="second">
            <div>
              <p style="font-size: 14px;font-weight: 400;color: #333333;line-height: 25px;">{{intelitem.target}}</p>
            </div>
          </el-tab-pane>
          <el-tab-pane label="操作流程" name="third">
           <div>
            <p style="font-size: 14px;font-weight: 400;color: #333333;line-height: 40px;">{{intelitem.operationProcedure}}</p>
           </div>
          </el-tab-pane>
          <el-tab-pane label="使用手册" name="fourth">
            <div>
              <p style="font-size: 12px;font-weight: 400;color: #000;padding-top: 25px;line-height: 20px;">{{intelitem.serviceManual}}</p>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, onUnmounted,computed} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { setStorage,getStorage,Newdata} from "@/js/common";
import breadcrumb from "@/components/breadcrumb";
import {courseData,subscribeCourse} from "@/js/caseApi";
import {F_Insert_browsingHistory,F_Insert_trainingRecord,f_go_systemExperienceTraining} from "@/js/homeListApi.js"
import {baseUrl} from '@/js/util.js'
import { ElMessage } from 'element-plus';
import { ref } from 'vue'
export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      baseUrl: baseUrl,
      userId: getStorage('userId'),
      intelitem:getStorage('intelitem'),
      StudentOrTeacher:getStorage('StudentOrTeacher'),
      activeName:'first',
      activeNames:[1],
      zhiduRate:5,
      caozuoRate:0,
      pingfendialog:false,
    
     
    });
   
    const methods = {
      //进入此页面增加一次浏览量
      browsingHistory() {
        let datas = {
          courseId: state.intelitem.id,
          allCount: 0,
          monthCount: 1,
        }
        F_Insert_browsingHistory(datas).then((res) => {
          if (res.data.code == 1) {
            //console.log('添加该课程浏览量成功');
          }
        })
      },
      //点击考核
      goexamine(){
        let datas = {
          systemExperienceId: state.intelitem.id,
        }
        f_go_systemExperienceTraining(datas).then((res) => {
          if (res.data.code == 1) {
            setStorage('intelexamine', res.data.data)
            //console.log(res.data.data, '点击考核');
            router.push('/home/intelexamine')
          } else {
            //console.log(res.data.data, '点击考核');
            ElMessage.error(res.data.message)
          }
        })
      },
      //点击去实训
      scoreClick(){
        let datas = {
          trainingId: state.intelitem.id,
          count: 1,
        }
        F_Insert_trainingRecord(datas).then((res) => {
          if (res.data.code == 1) {
            console.log('添加实训次数成功');
            // 跳转实训页面
            window.open('http://whpugd.hiaipc.com/#/')
          } else {
            ElMessage.error(res.data.message)
          }
        })
      },

      




      handleClick(){

      },
      pingfendialoging(){//点击评分开启评分弹窗
        state.pingfendialog = true;
        console.log('222222 触发评分弹窗')
      },
      beforedialog(){//点击取消或者点击空白关闭弹窗
        state.pingfendialog = false;
        console.log('444444 关闭评分弹窗')
      },
      quedingdialog(){//点击确定进行评分，并且关闭评分弹窗

        state.pingfendialog = false;
      },
     
      courseClick(){
        router.push('/home/intelligentProduction')
      },
      indexClick(){
        store.commit("SetnarbarMenuActive", 1);
        setStorage("narbarMenuActive", 1);
        router.push('/home/index');
      },
      
      scoreClick2(){
        window.open('http://47.95.149.159:8012/')
      },


    };
    onMounted(() => {
      console.log(state.intelitem);
      methods.browsingHistory();




   
    });
    onUnmounted(() => {});
  
    return {
      ...toRefs(state),
      ...methods,
    };
  },
  components: { breadcrumb },
};
</script>
<style lang="scss" scoped>
#intelligentConstructionDetail {
  height: 100%;
  position: relative;
  background: #F7F8FA;
  .it-detail{
    width: 66.5%;
    height: 100%;
    margin: 0 auto;
  }
  .breadCrumbs{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
  }

  .BasicInformation{
    width: 100%;
    height: 250px;
    background: #fff;
    border-radius: 5px;
    display: flex;

    .BasicRight{
      width: 65%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-left: 6px;

      .BasicRightitem{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .BasicTitle{
          font-size: 16px;
          font-weight: bold;
          color: #333;
        }
        .Basicflx{
          width: 65%;
          display: flex;

          .Basicflxitem{
            width: 100%;
          }
        }

      }

    }

  }

  .flxitemtitle{
    width: 100%;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666;

  }
  .flxitemtext{
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin: 0 3% 0 0;
  }

  .boxFour{
    width: 100%;
    min-height: 550px;
    border-radius: 5px;
    margin-top: 10px;
    background: #fff;
  }
  .demo-tabs{
    padding: 10px 15px 10px 15px;
    :deep .el-tabs__item{
       font-size: 18px !important;
    }
  }

  .boxCenter{
    width: 100%;
    min-height: 100px;
    border-radius: 5px;
    margin-top: 10px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .centitle{
    width: 140px;
    height: 44px;
    //background: #333;
    //background: url(../../../../src/assets/img/ConstructionDetail/pingfen.png) no-repeat;
    //background-position: right top;
    text-align: left;
    line-height: 44px;
    padding-left: 30px;

  }
  .cenping{
    min-width: 200px;
    height: 44px;
    text-align: left;
    line-height: 44px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    cursor:pointer;
  }



}

</style>
